import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TitleStrategy, provideRouter } from '@angular/router';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { provideEffects } from '@ngrx/effects';
import { provideState, provideStore } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ClipboardModule } from 'ngx-clipboard';
import { BASE_PATH } from '../../generated/api';
import { environment } from '../environments/environment';
import { FakeAPIService } from './_fake';
import { routes } from './app.routes';
import { AuthenticationService } from './services/authentication.service';
import { CustomAdapter } from './shared/custom-date-adapter';
import { CustomDateParserFormatter } from './shared/custom-date-formatter';
import { CustomTitleStrategy } from './shared/custom-title.strategy';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { auth } from './store/auth';
import { tenants } from './store/tenants';

export const appConfig: ApplicationConfig = {
  providers: [
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: BASE_PATH,
      useValue: environment.apiBasePath,
    },
    importProvidersFrom(
      TranslateModule.forRoot(),
      ClipboardModule,
      // #fake-start#
      environment.isMockEnabled
        ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
            passThruUnknownUrl: true,
            dataEncapsulation: false,
          })
        : [],
      // #fake-end#
      InlineSVGModule.forRoot(),
      SweetAlert2Module.forRoot(),
    ),
    { provide: TitleStrategy, useClass: CustomTitleStrategy },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    provideAnimations(),
    AuthenticationService,
    provideStore(),
    provideState({ name: tenants.key, reducer: tenants.reducer }),
    provideState({ name: auth.key, reducer: auth.reducer }),
    provideEffects(auth.effects, tenants.effects),
  ],
};
