import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { catchError, map, of } from 'rxjs';
import { AuthenticationService } from '../../../services/authentication.service';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const authenticationService = inject(AuthenticationService);
  const router = inject(Router);

  return authenticationService.isAuthenticated$.pipe(
    map((result) => {
      if (result) {
        return true;
      }
      return router.createUrlTree(['auth'], {
        queryParams: { redirectTo: state.url },
      });
    }),
    catchError(() => of(router.parseUrl('/auth'))),
  );
};
