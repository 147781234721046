import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslationService } from './modules/i18n';
// language list
import { ScriptsInitComponent } from './_metronic/layout/components/scripts-init/scripts-init.component';
import { ThemeModeService } from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as enLang } from './modules/i18n/vocabs/en';

@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, ScriptsInitComponent],
})
export class AppComponent implements OnInit {
  constructor(
    private translationService: TranslationService,
    private modeService: ThemeModeService,
  ) {
    // register translations
    this.translationService.loadTranslations(enLang, deLang);
  }

  ngOnInit() {
    this.modeService.init();
  }
}
